import DB from "./db";
import {EntityData, GroupId, Id, LessonId, StudentId, TeacherId, UserId} from "./entities/entity";
import {GroupModel} from "./entities/groups/groupModel";
import {LessonModel} from "./entities/lesson";
import {ProgressReportModel} from "./entities/reports/report";
import {StudentModel} from "./entities/student";
import {TeacherModel} from "./entities/teacher";
import {UserModel} from "./entities/user";

interface CachedData<T> {
    data: T;
    timestamp: number;
}

const CACHED_DATA_MINUTES = 3;
const GROUPS = "groups";
const TEACHERS = "teachers";
const STUDENTS = "students";
const LESSONS_BY_GROUP = (groupId: GroupId): string => {
    return `lessons_${groupId}`;
};

export class SimpleCacheDb implements DB {
    private cached: Map<string, CachedData<any>> = new Map<string, CachedData<any>>();

    constructor(private db: DB) {}

    private clear(key: string) {
        this.cached.delete(key);
    }

    private tryGetDataFromCache<T>(key: string): T | undefined {
        const cachedData = this.cached.get(key) as CachedData<T>;

        if (cachedData) {
            const timeDiffInMinutes = Math.floor((Date.now() - cachedData.timestamp) / 1000 / 60);
            if (timeDiffInMinutes <= CACHED_DATA_MINUTES) return cachedData.data;
        }
        return undefined;
    }

    private async getOrFetchData<T>(key: string, fetch: () => Promise<T>): Promise<T> {
        const cachedData = this.tryGetDataFromCache<T>(key);

        if (cachedData) return cachedData;

        const data = await fetch();
        this.cached.set(key, {
            data,
            timestamp: Date.now(),
        });

        return data;
    }

    public async fetchUser(id: UserId): Promise<UserModel> {
        return this.db.fetchUser(id);
    }

    public fetchUserByAuthId(authId: Id): Promise<UserModel> {
        return this.db.fetchUserByAuthId(authId);
    }

    public fetchTeachers(): Promise<TeacherModel[]> {
        return this.getOrFetchData(TEACHERS, () => this.db.fetchTeachers());
    }

    public fetchStudents(): Promise<StudentModel[]> {
        return this.getOrFetchData(STUDENTS, () => this.db.fetchStudents());
    }

    public async fetchStudent(id: StudentId): Promise<StudentModel> {
        return this.db.fetchStudent(id);
    }

    public async fetchStudentsById(ids: StudentId[]): Promise<StudentModel[]> {
        const students = this.tryGetDataFromCache<StudentModel[]>(STUDENTS);
        if (students) return Promise.resolve(students.filter(s => ids.includes(s.id)));
        return this.db.fetchStudentsById(ids);
    }

    public fetchGroups(): Promise<GroupModel[]> {
        return this.getOrFetchData(GROUPS, () => this.db.fetchGroups());
    }

    public fetchGroup(id: GroupId): Promise<GroupModel> {
        return this.db.fetchGroup(id);
    }

    public fetchGroupsByTeacherId(id: TeacherId): Promise<GroupModel[]> {
        const groups = this.tryGetDataFromCache<GroupModel[]>(GROUPS);
        if (groups) return Promise.resolve(groups.filter(g => g.teacherIds.includes(id)));

        return this.db.fetchGroupsByTeacherId(id);
    }

    public fetchGroupsByStudentId(id: StudentId): Promise<GroupModel[]> {
        const groups = this.tryGetDataFromCache<GroupModel[]>(GROUPS);
        if (groups) return Promise.resolve(groups.filter(g => g.studentIds.includes(id)));

        return this.db.fetchGroupsByStudentId(id);
    }

    public fetchLessons(): Promise<LessonModel[]> {
        return this.db.fetchLessons();
    }

    public async fetchLesson(id: LessonId): Promise<LessonModel> {
        return this.db.fetchLesson(id);
    }

    public fetchLessonsByGroupId(id: GroupId): Promise<LessonModel[]> {
        return this.getOrFetchData(LESSONS_BY_GROUP(id), () => this.db.fetchLessonsByGroupId(id));
    }

    fetchProgressReports(id: StudentId, groupId: GroupId): Promise<ProgressReportModel[]> {
        return this.db.fetchProgressReports(id, groupId);
    }

    public fetchProgressReport(reportId: Id): Promise<ProgressReportModel> {
        return this.db.fetchProgressReport(reportId);
    }

    // endregion

    //region Update
    public async createGroup(group: EntityData<GroupModel>): Promise<GroupModel> {
        this.clear(GROUPS);
        return this.db.createGroup(group);
    }

    public async updateGroup(group: GroupModel): Promise<GroupModel> {
        this.clear(GROUPS);
        this.clear(LESSONS_BY_GROUP(group.id));
        return this.db.updateGroup(group);
    }

    public deleteGroup(groupId: GroupId): Promise<void> {
        this.clear(GROUPS);
        this.clear(LESSONS_BY_GROUP(groupId));
        return this.db.deleteGroup(groupId);
    }

    public async addStudentToGroup(group: GroupModel, student: StudentId): Promise<void> {
        this.clear(GROUPS);
        return this.db.addStudentToGroup(group, student);
    }

    public async removeStudentFromGroup(group: GroupModel, student: StudentId): Promise<void> {
        this.clear(GROUPS);
        return this.db.removeStudentFromGroup(group, student);
    }

    public async createStudent(student: EntityData<StudentModel>): Promise<StudentModel> {
        this.clear(STUDENTS);
        return this.db.createStudent(student);
    }

    public async updateStudent(student: StudentModel): Promise<StudentModel> {
        this.clear(STUDENTS);
        return this.db.updateStudent(student);
    }

    public async deleteStudent(student: StudentModel): Promise<void> {
        this.clear(STUDENTS);
        return this.db.deleteStudent(student);
    }

    public async resetUser(student: StudentModel): Promise<void> {
        this.clear(STUDENTS);
        return this.db.resetUser(student);
    }

    public async createLesson(lesson: EntityData<LessonModel>): Promise<LessonModel> {
        this.clear(LESSONS_BY_GROUP(lesson.groupId));
        return this.db.createLesson(lesson);
    }

    public async updateLesson(lesson: LessonModel): Promise<LessonModel> {
        this.clear(LESSONS_BY_GROUP(lesson.groupId));
        return this.db.updateLesson(lesson);
    }

    public deleteLesson(lesson: LessonModel): Promise<void> {
        this.clear(LESSONS_BY_GROUP(lesson.groupId));
        return this.db.deleteLesson(lesson);
    }

    public deleteLessonsForGroup(groupId: GroupId): Promise<void> {
        this.clear(LESSONS_BY_GROUP(groupId));
        return this.db.deleteLessonsForGroup(groupId);
    }

    public createProgressReport(report: EntityData<ProgressReportModel>): Promise<ProgressReportModel> {
        return this.db.createProgressReport(report);
    }

    public updateProgressReport(report: ProgressReportModel): Promise<void> {
        return this.db.updateProgressReport(report);
    }

    public deleteProgressReport(report: ProgressReportModel): Promise<void> {
        return this.db.deleteProgressReport(report);
    }

    //endregion
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import {DbContext} from "./context";
import DB from "./DB/db";
import {FirebaseDb} from "./DB/firestore/FirebaseDb";
import {SimpleCacheDb} from "./DB/SimpleCacheDb";
import "./index.css";

const database: DB = new SimpleCacheDb(new FirebaseDb());

ReactDOM.render(
    <DbContext.Provider value={database}>
        <App />
    </DbContext.Provider>,
    document.getElementById("root") as HTMLElement,
);

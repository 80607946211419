import firebase from "firebase/app";
import {Entity, Id} from "../entities/entity";
import {GroupModel} from "../entities/groups/groupModel";
import {LessonModel} from "../entities/lesson";
import {ProgressReportModel} from "../entities/reports/report";
import {UserModel} from "../entities/user";
import {fbDataToGroup} from "./converters/groupConverter";
import {fbDataToLesson} from "./converters/lessonConverter";
import {fbDataToProgressReportModel} from "./converters/progressReportConverter";
import {fbDataToUser} from "./converters/userConverter";
import {FbUserModelData, ToModel} from "./types";

export function docToEntity<T extends Entity<Id>, R>(
    doc: firebase.firestore.DocumentSnapshot,
    transform: ToModel<T, R>,
): T | undefined {
    const data = doc.data();
    if (!data) {
        return undefined;
    }
    const result = {
        id: doc.id,
        ...transform(data as R),
    };
    return (result as unknown) as T;
}

export function docToUser(doc: firebase.firestore.DocumentSnapshot): UserModel | undefined {
    return docToEntity<UserModel, FbUserModelData>(doc, fbDataToUser);
}

export function docToGroup(doc: firebase.firestore.DocumentSnapshot): GroupModel | undefined {
    return docToEntity(doc, fbDataToGroup);
}

export function docToLesson(doc: firebase.firestore.DocumentSnapshot): LessonModel | undefined {
    return docToEntity(doc, fbDataToLesson);
}

export function docToProgressReport(doc: firebase.firestore.DocumentSnapshot): ProgressReportModel | undefined {
    return docToEntity(doc, fbDataToProgressReportModel);
}

import firebase from "firebase/app";
import {createUser, deleteUserAuth} from "../../cloudFunctions/functions";
import {firestore, storage} from "../../init-firebase";
import {generatePassword, sleep} from "../../util/Util";
import DB from "../db";
import {Entity, EntityData, GroupId, Id, LessonId, StudentId, TeacherId, UserId} from "../entities/entity";
import {GroupModel} from "../entities/groups/groupModel";
import {LessonModel} from "../entities/lesson";
import {ProgressReportModel} from "../entities/reports/report";
import {StudentModel} from "../entities/student";
import {TeacherModel} from "../entities/teacher";
import {UserModel, UserRole} from "../entities/user";
import {fbDataToGroup, groupToFbData} from "./converters/groupConverter";
import {fbDataToLesson, lessonToFbData} from "./converters/lessonConverter";
import {fbDataToProgressReportModel, progressReportToFbData} from "./converters/progressReportConverter";
import {fbDataToUser, userToFbData} from "./converters/userConverter";
import {docToEntity, docToGroup, docToLesson, docToProgressReport, docToUser} from "./helpers";
import {ToModel} from "./types";

type FbQuery = firebase.firestore.Query;

const USERS = "users";
const GROUPS = "groups";
const LESSONS = "lessons";
const PROGRESS_REPORTS = "p_reports";

export class FirebaseDb implements DB {
    //region Fetch

    private async fetchEntities<T extends Entity<Id>, R>(
        collectionQuery: FbQuery,
        transform: ToModel<T, R>,
    ): Promise<T[]> {
        const entitiesSnapshot = await collectionQuery.get();
        const entities: T[] = [];
        entitiesSnapshot.forEach(doc => {
            const entity = docToEntity<T, R>(doc, transform);
            if (entity) {
                entities.push(entity);
            }
        });
        return entities;
    }

    private async fetchUsers<T extends UserRole>(role: T): Promise<UserModel[]> {
        const usersQuery = firestore.collection(USERS).where("role", "==", role);
        return this.fetchEntities(usersQuery, fbDataToUser);
    }

    public async fetchUser(id: UserId): Promise<UserModel> {
        const userSnapshot = await firestore.collection(USERS).doc(id).get();
        const userModel = docToUser(userSnapshot);
        if (userModel) {
            return userModel;
        } else {
            return Promise.reject(`No such user ${id}`);
        }
    }

    public async fetchUserByAuthId(authId: Id): Promise<UserModel> {
        const usersSnapshot = await firestore.collection(USERS).where("authId", "==", authId).get();

        if (usersSnapshot.size <= 0) {
            return Promise.reject(`No such user ${authId}`);
        }

        const userModel = docToUser(usersSnapshot.docs[0]);
        if (userModel) return userModel;
        else return Promise.reject(`No such user ${authId}`);
    }

    public async resetUser(student: StudentModel): Promise<void> {
        if (student.authId) {
            if (!(await deleteUserAuth(student.authId))) return;
            await sleep(1000);
            await this.updateStudent({...student, authId: undefined});
        }
        return;
    }

    public async fetchTeachers(): Promise<TeacherModel[]> {
        return this.fetchUsers(UserRole.Teacher);
    }

    public fetchStudents(): Promise<StudentModel[]> {
        return this.fetchUsers(UserRole.Student);
    }

    public async fetchStudent(id: StudentId): Promise<StudentModel> {
        return (await this.fetchUser(id)) as StudentModel;
    }

    public async fetchStudentsById(ids: StudentId[]): Promise<StudentModel[]> {
        if (ids.length <= 0) {
            return [];
        }
        return (await this.fetchStudents()).filter(s => ids.includes(s.id));
    }

    public fetchGroups(): Promise<GroupModel[]> {
        return this.fetchEntities(firestore.collection(GROUPS), fbDataToGroup);
    }

    public async fetchGroup(id: GroupId): Promise<GroupModel> {
        const groupSnapshot = await firestore.collection(GROUPS).doc(id).get();
        const groupModel = docToGroup(groupSnapshot);
        if (groupModel) {
            return groupModel;
        } else {
            return Promise.reject(`No such group ${id}`);
        }
    }

    public fetchGroupsByTeacherId(id: TeacherId): Promise<GroupModel[]> {
        const groupsByTeacherIdQuery = firestore.collection(GROUPS).where("teacherIds", "array-contains", id);
        return this.fetchEntities(groupsByTeacherIdQuery, fbDataToGroup);
    }

    public fetchGroupsByStudentId(id: StudentId): Promise<GroupModel[]> {
        const groupsByStudentIdQuery = firestore.collection(GROUPS).where("studentIds", "array-contains", id);
        return this.fetchEntities(groupsByStudentIdQuery, fbDataToGroup);
    }

    public fetchLessons(): Promise<LessonModel[]> {
        return this.fetchEntities(firestore.collection(LESSONS), fbDataToLesson);
    }

    public async fetchLesson(id: LessonId): Promise<LessonModel> {
        const lessonSnapshot = await firestore.collection(LESSONS).doc(id).get();
        const lessonModel = docToLesson(lessonSnapshot);
        if (lessonModel) {
            return lessonModel;
        } else {
            return Promise.reject(`No such lesson ${id}`);
        }
    }

    public fetchLessonsByGroupId(id: GroupId): Promise<LessonModel[]> {
        const lessonsByGroupIdQuery = firestore.collection(LESSONS).where("groupId", "==", id);
        return this.fetchEntities(lessonsByGroupIdQuery, fbDataToLesson);
    }

    fetchProgressReports(id: StudentId, groupId: GroupId): Promise<ProgressReportModel[]> {
        const reportsQuery = firestore
            .collection(PROGRESS_REPORTS)
            .where("studentId", "==", id)
            .where("groupId", "==", groupId);
        return this.fetchEntities(reportsQuery, fbDataToProgressReportModel);
    }

    public async fetchProgressReport(reportId: Id): Promise<ProgressReportModel> {
        const reportDoc = await firestore.collection(PROGRESS_REPORTS).doc(reportId).get();
        const reportModel = docToProgressReport(reportDoc);
        if (reportModel) return reportModel;
        else return Promise.reject(`No such report: ${reportId}`);
    }

    // endregion

    //region Update
    public async createGroup(group: EntityData<GroupModel>): Promise<GroupModel> {
        const docRef = await firestore.collection(GROUPS).add(groupToFbData(group));
        return {
            ...group,
            id: docRef.id,
        };
    }

    public async updateGroup(group: GroupModel): Promise<GroupModel> {
        await firestore.collection(GROUPS).doc(group.id).set(groupToFbData(group));
        return group;
    }

    public deleteGroup(groupId: GroupId): Promise<void> {
        return firestore.collection(GROUPS).doc(groupId).delete();
    }

    public async addStudentToGroup(group: GroupModel, student: StudentId): Promise<void> {
        const groupData = groupToFbData(group);
        if (!groupData.studentIds) {
            return;
        }

        if (groupData.studentIds.includes(student)) {
            return;
        }
        groupData.studentIds.push(student);
        await firestore.collection(GROUPS).doc(group.id).set(
            {
                studentIds: groupData.studentIds,
            },
            {merge: true},
        );
    }

    public async removeStudentFromGroup(group: GroupModel, student: StudentId): Promise<void> {
        const groupData = groupToFbData(group);
        if (!groupData.studentIds) {
            return;
        }

        const indexOfStudent = groupData.studentIds.findIndex(s => s === student);
        if (indexOfStudent >= 0) {
            groupData.studentIds.splice(indexOfStudent, 1);
            await firestore.collection(GROUPS).doc(group.id).set(
                {
                    studentIds: groupData.studentIds,
                },
                {merge: true},
            );
        }
    }

    public async createStudent(student: EntityData<StudentModel>): Promise<StudentModel> {
        const studentToCreate = {
            ...student,
        };

        if (studentToCreate.email) {
            studentToCreate.authId = await createUser(studentToCreate.email, generatePassword(8));
        }

        const docRef = await firestore.collection(USERS).add(userToFbData(studentToCreate));

        return {
            ...studentToCreate,
            id: docRef.id,
        };
    }

    public async updateStudent(student: StudentModel): Promise<StudentModel> {
        const studentToUpdate = {
            ...student,
        };

        if (studentToUpdate.email && !studentToUpdate.authId) {
            studentToUpdate.authId = await createUser(studentToUpdate.email, generatePassword(8));
        }

        await firestore.collection(USERS).doc(studentToUpdate.id).set(userToFbData(studentToUpdate));

        return studentToUpdate;
    }

    public async deleteStudent(student: StudentModel): Promise<void> {
        if (student.authId) if (!(await deleteUserAuth(student.authId))) return;

        if (student.avatar) await storage.ref().child(student.avatar).delete();

        return firestore.collection(USERS).doc(student.id).delete();
    }

    public async createLesson(lesson: EntityData<LessonModel>): Promise<LessonModel> {
        const docRef = await firestore.collection(LESSONS).add(lessonToFbData(lesson));
        return {
            ...lesson,
            id: docRef.id,
        };
    }

    public async updateLesson(lesson: LessonModel): Promise<LessonModel> {
        await firestore.collection(LESSONS).doc(lesson.id).set(lessonToFbData(lesson));
        return lesson;
    }

    public deleteLesson(lesson: LessonModel): Promise<void> {
        return firestore.collection(LESSONS).doc(lesson.id).delete();
    }

    public async deleteLessonsForGroup(groupId: GroupId): Promise<void> {
        const lessonsToDelete = await firestore.collection(LESSONS).where("groupId", "==", groupId).get();
        const deletePromises: Array<Promise<void>> = [];
        lessonsToDelete.forEach(doc => deletePromises.push(doc.ref.delete()));
        await Promise.all(deletePromises);
    }

    public async createProgressReport(report: EntityData<ProgressReportModel>): Promise<ProgressReportModel> {
        const docRef = await firestore.collection(PROGRESS_REPORTS).add(progressReportToFbData(report));
        return {
            ...report,
            id: docRef.id,
        };
    }

    public async updateProgressReport(report: ProgressReportModel): Promise<void> {
        return await firestore.collection(PROGRESS_REPORTS).doc(report.id).set(progressReportToFbData(report));
    }

    deleteProgressReport(report: ProgressReportModel): Promise<void> {
        return firestore.collection(PROGRESS_REPORTS).doc(report.id).delete();
    }

    //endregion
}
